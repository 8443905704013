import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { useContentSetting } from "../../../Hooks/ContentSetting";
import moment from "moment";
import { formatTime } from "../../../utils/constant";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { logo } from "../../../assets";

export default function ParticipantView(props) {
  const { setBoxType, boxType, cameraOn } = props;
  const { myStream, hostStream } = useClassRoom();
  const myVideo = useRef();
  const hostStreamVideo = useRef();

  let total_time = 0;
  const { startedMeeting } = useContentSetting();
  if (startedMeeting) {
    const startTime = moment(startedMeeting.call_started_time);
    const endTime = moment(new Date());
    total_time = endTime.diff(startTime, "seconds");
  }
  const [timer, setTimer] = useState(total_time);

  const handleCloseBox = () => {
    setBoxType("CIRCLE");
  };

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (myVideo.current && myStream) {
      myVideo.current.srcObject = myStream;
    }
  }, [myVideo, myStream]);

  useEffect(() => {
    if (hostStreamVideo.current && hostStream) {
      hostStreamVideo.current.srcObject = hostStream;
    }
  }, [hostStreamVideo, hostStream]);

  return (
    <div className="meeting-wrapper">
      {boxType === "CIRCLE" ? (
        <div className="change-call-view">
          <Tooltip title={`Open Call Tools`}>
            <div onClick={handleCloseBox}>
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon={`ic:sharp-open-with`}
              />
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className="meeting-top-section">
          <div className="change-call-view">
            <Tooltip title={`Close Box`}>
              <div onClick={handleCloseBox}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`ic:baseline-close`}
                />
              </div>
            </Tooltip>
          </div>
          <div className="call-timer-section">
            <div className="call-timer-box">{formatTime(timer)}</div>
          </div>
          <div className="change-call-view">
            <Tooltip title={`${boxType === "BOX" ? "Full View" : "Box View"}`}>
              <div
                onClick={() => setBoxType(boxType === "BOX" ? "FULL" : "BOX")}
              >
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    boxType === "BOX"
                      ? "majesticons:open"
                      : "mdi:arrow-collapse-all"
                  }`}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      <video ref={myVideo} muted autoPlay className="w-100" />
      {!cameraOn && (
        <div className="image-logo-box">
          <img src={logo} alt="" />
        </div>
      )}
      <audio ref={hostStreamVideo} autoPlay />
    </div>
  );
}
