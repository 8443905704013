import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { quaidaPagesBaseUrl } from "../../config/config";

export default function QuidaEnglishIndexMainPage() {
  const params = useParams();
  const page_index = params.page_index.replace(/\D/g, ""); // getting part index from params
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page_index]);

  return (
    <div className="container-fluid theme-card mt-3 p-5">
      <div class="row pb-5">
        <div class="col-12">
          <div class="back-button-box">
            <Link to={`/lesson-books/quida-english`}>
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
        </div>
        <div class="col-12 holy-quran-index-pages">
          <div class="text-center lesson-main-page-number mb-3 arrows-on-main-page">
            <h2>
              {page_index > 1 && (
                <Link
                  to={`/lesson-books/quida-english/page-index-${
                    Number(page_index) - 1
                  }`}
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              )}
              &nbsp;&nbsp;&nbsp;Noorani Quida Page {page_index}
              &nbsp;&nbsp;&nbsp;
              {page_index < 33 && (
                <Link
                  to={`/lesson-books/quida-english/page-index-${
                    Number(page_index) + 1
                  }`}
                >
                  <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </h2>
          </div>
          <img
            src={`${quaidaPagesBaseUrl}NQ-eng-${page_index}.jpg`}
            alt=""
            className="img-fluid w-100"
          />
          <div class="text-center lesson-main-page-number arrows-on-main-page">
            <h2>
              {page_index > 1 && (
                <Link
                  to={`/lesson-books/quida-english/page-index-${
                    Number(page_index) - 1
                  }`}
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              )}
              &nbsp;&nbsp;&nbsp;Noorani Quida Page {page_index}
              &nbsp;&nbsp;&nbsp;
              {page_index < 33 && (
                <Link
                  to={`/lesson-books/quida-english/page-index-${
                    Number(page_index) + 1
                  }`}
                >
                  <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
