import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import ChattingMessages from "../../components/Chatting/ChattingMessages";
import {
  messages_list_api,
  read_messages_api,
} from "../../DAL/Messages/Messages";
import { useContentSetting } from "../../Hooks/ContentSetting";
import InputEditor from "../../components/GeneralComponents/InputEditor";

export default function Chatting() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, conversation, socket } = useContentSetting();
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [messagesList, setMessagesList] = useState([]);

  const scroll_to_bottom_on_message = () => {
    const chat_box = document.getElementById("chat-box-body");
    setTimeout(() => {
      if (chat_box) {
        if (isLoadingFirst) {
          chat_box.scrollTop = chat_box.scrollHeight;
          setIsLoadingFirst(false);
        } else {
          chat_box.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
        }
      }
    }, 100);
  };

  const get_messages_list = async () => {
    setIsLoadingMessages(true);
    setIsLoadingFirst(true);
    const result = await messages_list_api(conversation?._id);
    if (result.code === 200) {
      setMessagesList(result.messages_list);
      read_messages_api(conversation._id);
      setIsLoadingMessages(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMessages(false);
    }
  };

  useEffect(() => {
    get_messages_list();
  }, [conversation]);

  useEffect(() => {
    scroll_to_bottom_on_message();
  }, [messagesList, userInfo._id]);

  const handle_message_receiver = (result) => {
    if (result.code === 200) {
      let members = result.members;
      let find_member = members.find((member) => member._id === userInfo._id);
      if (find_member) {
        setMessagesList((old) => [...old, result.message]);
      }
      if (result.message.sender_id === userInfo._id) {
        setMessageText("");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const delete_message_from_class_receiver = (result) => {
    setMessagesList((old) =>
      old.filter((message) => message._id !== result.data.message_id)
    );
  };

  const handle_update_message_receiver = (result) => {
    if (result.data.conversation_id === conversation?._id) {
      setMessagesList((messages) => {
        return messages.map((old) => {
          if (old._id === result.last_message._id) {
            return result.last_message;
          } else {
            return old;
          }
        });
      });
    }
  };

  const update_call_status = (values, status) => {
    const { data, chat_message } = values;
    let last_call_id = data.last_call_id;
    setMessagesList((old) => {
      return old.map((message) => {
        if (message._id === last_call_id) {
          if (status === "ended") {
            return chat_message;
          }
          return { ...message, call_status: status };
        }
        return message;
      });
    });
  };

  useEffect(() => {
    socket.on("send_message_from_class_receiver", (data) => {
      handle_message_receiver(data);
    });
    socket.on("delete_message_from_class_receiver", (data) => {
      delete_message_from_class_receiver(data);
    });
    socket.on("update_message_from_class_receiver", (data) => {
      handle_update_message_receiver(data);
    });
    socket.on("call:accepted", (data) => {
      update_call_status(data, "started");
    });
    socket.on("call:canceled", (data) => {
      update_call_status(data, "canceled");
    });
    socket.on("call:declined", (data) => {
      update_call_status(data, "declined");
    });
    socket.on("call:ended", (data) => {
      update_call_status(data, "ended");
    });
    socket.on("call:no_answered", (data) => {
      update_call_status(data, "no_answered");
    });
    socket.on("call:unavailable", (data) => {
      update_call_status(data, "unavailable");
    });

    return () => {
      socket.off("send_message_from_class_receiver");
      socket.off("update_message_from_class_receiver");
      socket.off("delete_message_from_class_receiver");
      socket.off("call:accepted");
      socket.off("call:declined");
      socket.off("call:canceled");
      socket.off("call:ended");
      socket.off("call:no_answered");
      socket.off("call:unavailable");
    };
  }, []);

  const inputData = {
    messageText,
    setMessageText,
    conversation,
  };

  return (
    <>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-12">
            <div className="chatting-section">
              <ChattingMessages
                messagesList={messagesList}
                isLoadingMessages={isLoadingMessages}
              />
              <InputEditor {...inputData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
