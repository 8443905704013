import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import { useContentSetting } from "../../Hooks/ContentSetting";
import AddOrUpdatePersonalNote from "../../components/PersonalNotes/AddOrUpdatePersonalNote";
import {
  delete_personal_note_api,
  personal_notes_list_api,
} from "../../DAL/PersonalNotes/PersonalNotes";
import moment from "moment";
import CustomPopover from "../../components/GeneralComponents/CustomPopover";
import RecordNotFound from "../../components/GeneralComponents/RecordNotFound";

export default function PersonalNotes() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [personalNotes, setPersonalNotes] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const { userInfo } = useContentSetting();

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_personal_notes_list = async () => {
    const result = await personal_notes_list_api(userInfo.role);
    if (result.code === 200) {
      setPersonalNotes(result.personal_notes);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_personal_note_api(selectedObject._id);
    if (result.code === 200) {
      const data = personalNotes.filter(
        (personal_note) => personal_note._id !== selectedObject._id
      );
      setPersonalNotes(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_personal_notes_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-2 mui-table-heading">
              <h2>Personal Notes</h2>
            </div>
          </div>
          <div className="col-md-6 text-end">
            <div className="add--button mb-3">
              <button onClick={handleOpenDrawer}>Add Note</button>
            </div>
          </div>
          <div className="col-12">
            {personalNotes.length > 0 ? (
              personalNotes.map((note) => {
                return (
                  <div
                    className="personal-notes-card p-3 mb-2"
                    style={{
                      backgroundColor: note.background_color,
                      color: note.text_color,
                      borderColor: note.text_color,
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6>{note.title}</h6>
                      </div>
                      <div className="d-flex">
                        <div>
                          {moment(note.createdAt).format("DD-MM-YYYY hh:mm A")}
                        </div>
                        <CustomPopover menu={MENU_OPTIONS} data={note} />
                      </div>
                    </div>
                    <div className="descption">{note.description}</div>
                  </div>
                );
              })
            ) : (
              <RecordNotFound title="Record Not Found" />
            )}
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType == "EDIT" ? "Update" : "Add"} Note`}
        componentToPassDown={
          <AddOrUpdatePersonalNote
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            setPersonalNotes={setPersonalNotes}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this personal note?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
