import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { update_user_profile } from "../../DAL/LogedInUser/LogedInUser";
var moment = require("moment");

export default function UpdateUserProfile({
  onCloseDrawer,
  userInfo,
  setUserInfo,
  setStudentsGroup,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [birthDate, setbirthDate] = useState(new Date());
  const [state, setState] = useState({
    name: "",
    password: "",
    skype_link: "",
    guardian_name: "",
    whatsapp: "",
    address: "",
    gender: "male",
    relation: "father",
  });

  const handleChangeDate = (newValue) => {
    setbirthDate(newValue.$d);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("skype_link", state.skype_link);
    formData.append("guardian_name", state.guardian_name);
    formData.append("whatsapp", state.whatsapp);
    formData.append("address", state.address);
    formData.append("gender", state.gender);
    formData.append("birth_date", moment(birthDate).format("YYYY-MM-DD"));
    formData.append("relation", state.relation);
    if (image) {
      formData.append("profile_image", image);
    }
    const result = await update_user_profile(formData);
    if (result.code === 200) {
      setUserInfo(result.student_user);
      setStudentsGroup(result.student_group);
      localStorage.setItem(`user_data`, JSON.stringify(result.student_user));
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setState(userInfo);
    setbirthDate(
      moment(userInfo.birth_date, "DD-MM-YYYY").format("MM-DD-YYYY")
    );
    if (userInfo.profile_image) {
      setPreviews(s3baseUrl + userInfo.profile_image);
    }
  }, []);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="name"
                label="Name"
                variant="outlined"
                name="name"
                value={state.name}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date of Birth"
                  inputFormat="DD-MM-YYYY"
                  className="mt-4 form-control mui-date-picker"
                  value={birthDate}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Gender"
                  name="gender"
                  className="mui-select"
                  value={state.gender}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="skype_link"
                label="Skype Link"
                variant="outlined"
                name="skype_link"
                value={state.skype_link}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                className="mt-4 form-control"
                id="whatsapp"
                label="Whatsapp"
                variant="outlined"
                name="whatsapp"
                value={state.whatsapp}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="address"
                label="Address"
                variant="outlined"
                name="address"
                value={state.address}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="guardian_name"
                label="Guardian Name"
                variant="outlined"
                name="guardian_name"
                value={state.guardian_name}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="relation">Relation</InputLabel>
                <Select
                  labelId="relation"
                  id="relation"
                  label="Relation"
                  name="relation"
                  className="mui-select"
                  value={state.relation}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value="father">Father</MenuItem>
                  <MenuItem value="mother">Mother</MenuItem>
                  <MenuItem value="brother">Brother</MenuItem>
                  <MenuItem value="friend">Friend</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-12 mt-3 d-flex">
              {previews && (
                <span className="upload-file-preview mt-3">
                  <span onClick={handleRemove}>x</span>
                  <img src={previews} />
                </span>
              )}
              {previews == "" && (
                <span className="upload-button mt-2">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    name="affirmationImage"
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file">
                    <CloudUploadIcon />
                  </label>
                </span>
              )}
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
