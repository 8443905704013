import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { CURRENCIES, MONTHS_ARRAY, get_next_month } from "../../utils/constant";
import {
  add_fee_request_api,
  update_fees_management_api,
} from "../../DAL/FeesManagement/FeesManagement";
import ChooseImage from "../GeneralComponents/ChooseImage";
import { s3baseUrl } from "../../config/config";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useContentSetting } from "../../Hooks/ContentSetting";

const get_last_month = () => {
  var current_month = new Date().getMonth();
  let year = new Date().getFullYear();
  let last_month = current_month - 1;
  if (current_month === 0) {
    last_month = 11;
    year = year - 1;
  }
  return { month: MONTHS_ARRAY[last_month].value, year };
};

const FIRST_FEE = {
  month: get_last_month().month,
  year: get_last_month().year,
};

export default function AddOrUpdateFees({
  onCloseDrawer,
  setFeesList,
  formType,
  selectedObject,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, studentsGroup } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [oldImage, setOldImage] = useState("");

  let init_amount = 0;
  let init_currency = "usd";
  let isDisabled = false;
  if (userInfo.fees_setting?.amount && !studentsGroup?.fees_setting?.amount) {
    init_amount = userInfo.fees_setting?.amount;
    init_currency = userInfo.fees_setting?.currency;
    isDisabled = true;
  } else if (studentsGroup?.fees_setting?.amount) {
    init_amount = studentsGroup?.fees_setting?.amount;
    init_currency = studentsGroup?.fees_setting?.currency;
    isDisabled = true;
  }

  const EMPTY_VALUES = {
    amount: 0,
    currency: init_currency,
    fee_status: "submitted",
    month: "january",
    fees_info: [{ ...FIRST_FEE, amount: init_amount }],
    year: "2024",
    receipt_slip: "",
  };

  const [state, setState] = useState(EMPTY_VALUES);

  const handleAddFee = () => {
    setState((old) => {
      var last_fee = old.fees_info[old.fees_info.length - 1];
      let fee_obj = { ...last_fee, ...get_next_month(last_fee) };
      let fees_info = [...old.fees_info, fee_obj];
      return { ...old, fees_info };
    });
  };

  const handleRemoveFee = (index) => {
    setState((old) => {
      old.fees_info.splice(index, 1);
      return { ...old };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formType === "ADD" && !imagePreview) {
      let error_message = "Receipt slip is required";
      enqueueSnackbar(error_message, { variant: "error" });
      return;
    }
    setIsLoading(true);
    let created_for = userInfo._id;
    let request_type = "individual";
    if (studentsGroup?.fees_setting?.amount) {
      request_type = "group";
      created_for = studentsGroup?._id;
    }

    const formData = new FormData();
    formData.append("currency", state.currency);
    formData.append("fee_status", state.fee_status);
    formData.append("created_for", created_for);
    formData.append("request_type", request_type);
    formData.append("fees_info", JSON.stringify(state.fees_info));

    if (imagePreview) {
      formData.append("receipt_slip", state.receipt_slip);
    }

    const result =
      formType === "ADD"
        ? await add_fee_request_api(formData)
        : await update_fees_management_api(formData, state._id);
    if (result.code === 200) {
      let fee_request = result.fee_request;
      fee_request.table_avatar = {
        src: s3baseUrl + fee_request.receipt_slip,
        alt: "Receipt slip",
      };
      if (formType === "ADD") {
        setFeesList((old) => [fee_request, ...old]);
      } else {
        setFeesList((old) =>
          old.map((obj) => {
            if (obj._id === state._id) {
              return fee_request;
            }
            return obj;
          })
        );
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  const handleChangeFee = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...state.fees_info];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setState({ ...state, fees_info: temp_state });
  };

  const handleUpload = (event) => {
    setState((old) => ({ ...old, receipt_slip: event.target.files[0] }));
    const preview = URL.createObjectURL(event.target.files[0]);
    setImagePreview(preview);
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
      setOldImage(s3baseUrl + selectedObject.receipt_slip);
    }
  }, [formType, selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            {state.fees_info?.length > 0 &&
              state.fees_info.map((fee, index) => {
                return (
                  <>
                    <div className="col-12 col-md-6 col-lg-3">
                      <TextField
                        type="number"
                        className="mt-4 form-control"
                        id="amount"
                        label="Amount"
                        variant="outlined"
                        name="amount"
                        value={fee.amount}
                        onChange={(e) => {
                          handleChangeFee(e, index);
                        }}
                        required
                        inputProps={{ min: 1 }}
                        disabled={Boolean(formType === "EDIT" || isDisabled)}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <FormControl
                        variant="outlined"
                        className="mt-4 form-control"
                      >
                        <InputLabel id="month">Month</InputLabel>
                        <Select
                          labelId="month"
                          id="month"
                          label="Month"
                          name="month"
                          className="mui-select"
                          value={fee.month}
                          disabled={Boolean(formType === "EDIT")}
                          onChange={(e) => {
                            handleChangeFee(e, index);
                          }}
                        >
                          {MONTHS_ARRAY.map((month) => {
                            return (
                              <MenuItem value={month.value}>
                                {month.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <FormControl
                        variant="outlined"
                        className="mt-4 form-control"
                      >
                        <InputLabel id="year">Year</InputLabel>
                        <Select
                          labelId="year"
                          id="year"
                          label="Year"
                          name="year"
                          className="mui-select"
                          value={fee.year}
                          disabled={Boolean(formType === "EDIT")}
                          onChange={(e) => {
                            handleChangeFee(e, index);
                          }}
                        >
                          <MenuItem value="2023">2023</MenuItem>
                          <MenuItem value="2024">2024</MenuItem>
                          <MenuItem value="2025">2025</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-1 p-0 mt-4">
                      {index === state.fees_info?.length - 1 ? (
                        <div className="add-class-day mt-2">
                          <Tooltip title="Add More Fees" placement="left">
                            <AddCircleOutlineIcon
                              onClick={() => handleAddFee()}
                              className="mt-1 cursor-pointer"
                            />
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="remove mt-3">
                          <Tooltip title="Remove This Fee" placement="left">
                            <RemoveCircleOutlineIcon
                              onClick={() => handleRemoveFee(index)}
                              className="icon-remove-day mt-0"
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="currency">Currency</InputLabel>
                <Select
                  labelId="currency"
                  id="currency"
                  label="Currency"
                  name="currency"
                  className="mui-select"
                  disabled={Boolean(isDisabled || formType === "EDIT")}
                  value={state.currency}
                  onChange={handleChange}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <MenuItem value={currency.value}>
                        {currency.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <ChooseImage
                image_path={
                  imagePreview ? imagePreview : oldImage ? oldImage : ""
                }
                handleUpload={handleUpload}
                name="receipt_slip"
                label="Receipt Slip"
              />
            </div>
          </div>
          <div className="col-12 add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
