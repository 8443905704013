import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { dashboard_stats_api } from "../../DAL/Dashboard/Dashboard";
import DashboardSlider from "./DashboardSlider";
import {
  HOLY_QURAN_MAX_PAGES,
  MADANI_QAIDA_MAX_PAGES,
  NOORANI_QAIDA_MAX_PAGES,
  holy_quran_indexes,
} from "../../utils/constant";

export default function Dashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const [statsData, setStatsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const get_dashboard_stats = async () => {
    const result = await dashboard_stats_api();
    if (result.code === 200) {
      setStatsData(result);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_dashboard_stats();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  let slider_title = "";
  let para_show_title = "";
  let show_title = "";
  let max_value = 0;
  let page_number = 0;
  let part_mumber = 0;
  let find_quran_index = null;
  let last_report = statsData.last_reports;

  if (last_report) {
    page_number = last_report.page_number;
    part_mumber = last_report.part_mumber;
    if (last_report.lesson_book == "quaida") {
      max_value = NOORANI_QAIDA_MAX_PAGES;
      slider_title = `Noorani Quaida Progress`;
    } else if (last_report.lesson_book == "madani_quaida") {
      max_value = MADANI_QAIDA_MAX_PAGES;
      slider_title = `Madani Quaida Progress`;
    } else if (last_report.lesson_book == "quran") {
      max_value = HOLY_QURAN_MAX_PAGES;
      find_quran_index = holy_quran_indexes.find(
        (quran) => quran.part == part_mumber
      );
      slider_title = `Holy Quran Progress`;
    }

    if (find_quran_index) {
      let para_percentage =
        ((page_number - find_quran_index.min) /
          (find_quran_index.max - find_quran_index.min)) *
        100;
      para_show_title = `${Math.round(para_percentage)}%`;
    }

    let percentage = (page_number / max_value) * 100;
    show_title = `${Math.round(percentage)}%`;
  }

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fa-solid fa-list"></i>
            </div>
            <h3>{statsData.student_reports}</h3>
            <p>Total Reports</p>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fa-solid fa-book"></i>
            </div>
            <h3>{statsData.personal_note}</h3>
            <p>Personal Notes</p>
          </div>
        </div>

        <div className="col-12">
          {last_report && find_quran_index && (
            <div className="theme-card p-3 mb-3">
              <h4 className="my-3">Part {find_quran_index.part} Progress</h4>
              <DashboardSlider
                show_title={para_show_title}
                slider_value={page_number - find_quran_index.min}
                max_value={find_quran_index.max - find_quran_index.min}
              />
            </div>
          )}
          {last_report && (
            <div className="theme-card p-3">
              <h4 className="my-3">{slider_title}</h4>
              <DashboardSlider
                show_title={show_title}
                slider_value={page_number}
                max_value={max_value}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
