import React from "react";
import { baseUrl } from "../../config/config";

const handle_check_link = (link) => {
  return link.replace(baseUrl, "");
};

export default function ChooseImage(props) {
  const { image_path, handleUpload, name, label } = props;

  return (
    <div className="choose-image-container">
      <div className="choose-image-label">{label}</div>
      <div className="mt-2">
        <input
          color="primary"
          accept="image/*"
          type="file"
          id="icon-button-file1"
          style={{ display: "none" }}
          name={name}
          onChange={handleUpload}
        />
        <label className="choose-file-box" htmlFor="icon-button-file1">
          <span className="choose-file-button">
            <i class="fa-solid fa-arrow-up-from-bracket me-2"></i>Choose File
          </span>
        </label>
      </div>
      {handle_check_link(image_path) && (
        <div className="image-preview-box">
          <a href={image_path} target="_blank" rel="noopener noreferrer">
            <img src={image_path} alt="" />
          </a>
        </div>
      )}
    </div>
  );
}
