import React, { useContext, useState } from "react";
import { useContentSetting } from "./ContentSetting";
import { useEffect } from "react";
import { tune_file, ended_call_tune } from "../assets";
import Peer from "peerjs";

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";
let cameraError =
  "Camera permission is denied. Please enable it in your browser settings.";

const constraints = {
  video: {
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9,
  },
  audio: true,
};

const CreateClassRoom = React.createContext();
export const useClassRoom = () => useContext(CreateClassRoom);
export function ClassRoom({ children }) {
  const tune = new Audio(tune_file);
  const ended_call_file = new Audio(ended_call_tune);
  const [hostPeerID, setHostPeerID] = useState(null);
  const [myPeer, setMyPeer] = useState(null);
  const [myStream, setMyStream] = useState(null);
  const [hostStream, setHostStream] = useState(null);
  const [lastCallID, setLastCallID] = useState(null);
  const [autoJoin, setAutoJoin] = useState(true);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [callUser, setCallUser] = useState("");
  const { socket, userInfo, startedMeeting } = useContentSetting();
  const [startCallTune, setStartCallTune] = useState(tune);
  const [endedCallTune, setEndedCallTune] = useState(ended_call_file);

  const handleStopAudio = () => {
    startCallTune.pause();
    startCallTune.currentTime = 0;
  };

  const handleStartCall = async () => {
    var peer = new Peer();
    setMyPeer(peer);
    let peer_id;
    peer.on("open", (id) => {
      peer_id = id;
      console.log("PeerJS connection established with ID:", id);
    });

    peer.on("error", (err) => {
      console.error("PeerJS Error:", err);
    });

    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      alert(micError);
      return;
    }

    const camera = await navigator.permissions.query({ name: "camera" });
    if (camera.state === "denied") {
      alert(cameraError);
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const videoTracks = stream.getVideoTracks();
    videoTracks.forEach((track) => (track.enabled = false));
    setMyStream(stream);
    socket.emit("user:call", {
      to: callUser,
      type: "student",
      from: userInfo._id,
      meeting_id: peer_id,
    });
  };

  const handleIncommingCall = async (data) => {
    startCallTune.play();
    startCallTune.addEventListener("ended", () => {
      startCallTune.play();
    });
    let { from, meeting_id, last_message } = data;
    setCallUser(from);
    setLastCallID(last_message._id);
    setHostPeerID(meeting_id);
    socket.emit("ringing:call", {
      to: from,
      from: userInfo._id,
      meeting_id,
      last_call_id: last_message._id,
    });
    setIsIncomingCall(true);
  };

  const handleCallAccepted = async () => {
    handleStopAudio();
    setIsIncomingCall(false);
  };

  const handleStopCall = () => {
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
      setMyStream(null);
    }
    setHostStream(null);
    setLastCallID(null);
    setHostPeerID(null);
    if (myPeer) {
      myPeer.destroy();
      setMyPeer(null);
    }
    setIsCallAccepted(false);
    handleStopAudio();
    setCallUser("");
    endedCallTune.play();
  };

  useEffect(() => {
    socket.on("incomming:call", handleIncommingCall);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("call:canceled", handleStopCall);
    socket.on("call:ended", handleStopCall);
    socket.on("call:no_answered", handleStopCall);

    return () => {
      socket.off("incomming:call", handleIncommingCall);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("call:canceled", handleStopCall);
      socket.off("call:ended", handleStopCall);
      socket.off("call:no_answered", handleStopCall);
    };
  }, [socket, myPeer, myStream]);

  useEffect(() => {
    if (startedMeeting) {
    }
  }, []);

  const collection = {
    hostPeerID,
    isIncomingCall,
    autoJoin,
    callUser,
    startCallTune,
    lastCallID,
    isCallAccepted,
    hostStream,
    myStream,
    setCallUser,
    setMyPeer,
    handleStartCall,
    setIsIncomingCall,
    handleStopAudio,
    setIsCallAccepted,
    setHostStream,
    handleStopCall,
    setMyStream,
  };

  return (
    <CreateClassRoom.Provider value={collection}>
      {children}
    </CreateClassRoom.Provider>
  );
}
