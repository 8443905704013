import { Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { holy_quran_indexes } from "../../utils/constant";

export default function HolyQuranIndexes() {
  return (
    <div className="container-fluid theme-card mt-3 p-5">
      <div class="row pb-5">
        <div class="col-12 pb-3 d-flex">
          <div class="back-button-box">
            <Link to={`/lesson-books`}>
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
          <h2>Holy Quran</h2>
        </div>
        <Divider className="sidebar-divider mb-4" />
        {holy_quran_indexes.map((part, index) => {
          return (
            <div class="col-sm-12 col-md-4 col-lg-2">
              <div class="lesson-card-link">
                <Link to={`/lesson-books/quran-pak/part-index-${index + 1}`}>
                  <h2>Part No {index + 1}</h2>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
