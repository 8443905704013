import React from "react";
import ParticipantView from "./ParticipantView";
import { useState } from "react";
import { useEffect } from "react";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { logo } from "../../../assets";
import { useContentSetting } from "../../../Hooks/ContentSetting";
import Peer from "peerjs";

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";
let cameraError =
  "Camera permission is denied. Please enable it in your browser settings.";

const constraints = {
  video: {
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9,
  },
  audio: true,
};

export default function MeetingView(props) {
  const {
    isIncomingCall,
    setIsIncomingCall,
    callUser,
    startCallTune,
    setMyPeer,
    lastCallID,
    isCallAccepted,
    setIsCallAccepted,
    setHostStream,
    handleStopCall,
    myStream,
    setMyStream,
  } = useClassRoom();
  const { userInfo, socket } = useContentSetting();
  const { setBoxType, boxType } = props;
  const [micOn, setMicOn] = useState(true);
  const [cameraOn, setCameraOn] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);

  const acceptCall = async () => {
    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      alert(micError);
      return;
    }

    const camera = await navigator.permissions.query({ name: "camera" });
    if (camera.state === "denied") {
      alert(cameraError);
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const videoTracks = stream.getVideoTracks();
    videoTracks.forEach((track) => (track.enabled = false));
    setMyStream(stream);
    var peer = new Peer();
    setMyPeer(peer);

    peer.on("call", (call) => {
      call.answer(stream);
      call.on("stream", (stream) => {
        setHostStream(stream);
        setIsIncomingCall(false);
        setIsCallAccepted(true);
      });
    });

    peer.on("open", (id) => {
      const acceptData = {
        to: callUser,
        from: userInfo._id,
        peer_id: id,
        last_call_id: lastCallID,
      };
      socket.emit("call:accepted", acceptData);
    });

    peer.on("error", (err) => {
      console.error("PeerJS Error:", err);
    });
  };

  const handletoggleAudio = () => {
    if (!startCallTune.paused) {
      setIsPlaying(false);
      startCallTune.pause();
    } else {
      setIsPlaying(true);
      startCallTune.play();
    }
  };

  const handletoggleMic = () => {
    const audioTracks = myStream.getAudioTracks();
    audioTracks.forEach((track) => (track.enabled = !micOn));
    setMicOn(!micOn);
  };

  const handletoggleCam = () => {
    const videoTracks = myStream.getVideoTracks();
    videoTracks.forEach((track) => (track.enabled = !cameraOn));
    socket.emit("handle_video_paused", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
      is_paused: cameraOn,
    });
    setCameraOn(!cameraOn);
  };

  const handleEndCall = () => {
    socket.emit("call:ended", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    });
  };

  const handleDeclineCall = () => {
    socket.emit("call:declined", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    });
    handleStopCall();
  };

  const handleCancelCall = () => {
    socket.emit("call:canceled", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    });
    handleStopCall();
  };

  const handleNoAnswered = () => {
    let socketData = {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    };
    socket.emit("call:no_answered", socketData);
    handleStopCall();
  };

  useEffect(() => {
    let timeoutId;
    if (isIncomingCall) {
      timeoutId = setTimeout(() => {
        if (!isCallAccepted) {
          handleNoAnswered();
        }
      }, 40000);
    }
    if (isCallAccepted) {
      clearTimeout(timeoutId);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isIncomingCall, isCallAccepted]);

  return (
    <>
      {isCallAccepted ? (
        <>
          <ParticipantView
            setBoxType={setBoxType}
            boxType={boxType}
            cameraOn={cameraOn}
          />
        </>
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
      {boxType !== "CIRCLE" && (
        <div className="icon-box">
          {isIncomingCall ? (
            <>
              <Tooltip title={`Join Class`}>
                <div className="success-call-box" onClick={() => acceptCall()}>
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`carbon:phone-incoming`}
                  />
                </div>
              </Tooltip>
              <Tooltip title={`${isPlaying ? "Off" : "On"}`}>
                <div className="success-call-box" onClick={handletoggleAudio}>
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`${
                      isPlaying ? "fa-solid:volume-up" : "fa-solid:volume-mute"
                    }`}
                  />
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={`${micOn ? "Mute" : "Unmute"}`}>
                <div className="success-call-box" onClick={handletoggleMic}>
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`${micOn ? "bi:mic-fill" : "vaadin:mute"}`}
                  />
                </div>
              </Tooltip>
              <Tooltip
                title={`${cameraOn ? "Turn Off Camera" : "Turn On Camera"}`}
              >
                <div
                  className="success-call-box"
                  onClick={() => handletoggleCam()}
                >
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon={`${
                      cameraOn ? "ic:round-camera-alt" : "mdi:camera-off"
                    }`}
                  />
                </div>
              </Tooltip>
            </>
          )}

          <Tooltip
            title={`${
              isIncomingCall
                ? "Decline Call"
                : isCallAccepted
                ? "Drop Call"
                : "Cancel Call"
            }`}
            onClick={() => {
              if (isIncomingCall) {
                handleDeclineCall();
              } else if (isCallAccepted) {
                handleEndCall();
              } else {
                handleCancelCall();
              }
            }}
          >
            <div className="success-call-box error">
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon="subway:call-3"
              />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}
